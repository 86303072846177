<template>
  <div class="node-box">
    <div class="node-left">
      <div class="node-lefttitle">
        <img src="../../assets/img/fielHend.png" alt="" />
        <h4>设置登录数量</h4>
      </div>
      <div class="node-leftnum">
        <p>已购节点数：<span>{{AllNum}}</span>，已选人数：<span>{{personnel.length}}</span> 
        <!-- <span class="buy" @click="handlebuy()">点击购买节点</span> -->
        </p>
      </div>
      <div class="node-leftquree">
        <p>选择人员</p>
        <div class="node-input">
          <el-input v-model="filterText"></el-input>
          <button @click="btnA">查询</button>
          <button @click="Clearinput">清空</button>
        </div>
      </div>
      <div class="node-tree">
        <el-tree class="node-treebody" :data="data" show-checkbox node-key="id" :default-checked-keys="TreeData"
          :filter-node-method="filterNode" :props="defaultProps" @check-change="handlechange" :default-expand-all='true'
          ref="tree">
        </el-tree>
      </div>
    </div>

    <div class="node-right">
      <div>
        <div class="node-righttop">
          <p class="node-righttitle">已选人员</p>
          <p class="node-button" @click="handlesave()">保存</p>
        </div>
        <div class="node-per">

            <div class="node-persone" >
            <el-avatar style="margin-left:20px">{{ZhuGuanLiYuan.label}}</el-avatar>
            <p class="node-perlabel">{{ZhuGuanLiYuan.label}}</p>
          </div>

          <div class="node-persone" v-for="(item,i) in personnel" :key="i.id" v-show="item.id!=ZhuGuanLiYuan.staff_id">
            <el-avatar style="margin-left:20px">{{item.label}}</el-avatar>
            <p class="node-perlabel">{{item.label}}</p>
            <p class="node-oper" v-if=" ZhuGuanLiYuan.staff_id == item.staff_id "></p>
            <p class="node-oper" @click="handledelete(item)" v-else>移除</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    query_dept_staff_tree_aim_not,
    query_staff_node,
    update_staff_node,
    query_ent_node_num
  } from "../../api/store"
  import {
    query_ent_primary_manager,
    query_ent_primary_manager_detail
  } from "../../api/public"
  export default {
    mounted() {
      if (this.$store.state.ent_id) {
        query_dept_staff_tree_aim_not({
          data: {
            ent_id: this.$store.state.ent_id,
            //user_id: sessionStorage.getItem('user_id'),
          }
        }).then(res => {

          this.data = res.body.data
          console.log(this.data, 'kkkk')
        })
      } else {
        this.$message({
          message: '请先选择企业如没有企业请先认证企业',
          type: 'error'
        })
      }

      query_staff_node({
        data: {
          ent_id: this.$store.state.ent_id
        }
      }).then(res => {
        let arr = res.body.data
        let arr3 = []
        arr.forEach(x => {
          x.id = x.staff_id
          arr3.push(x.id)
        })
        this.personnel = arr
        this.$refs.tree.setCheckedKeys(arr3);

        // this.AllNum=res.body.data
        console.log(res, '企业节点数');
      })
      //企业节点数
      query_ent_node_num({
        data: {
          ent_id: this.$store.state.ent_id
        }
      }).then(res => {
        this.AllNum = res.body.data
        console.log(res, '企业节点数');
      })
    },
    methods: {
      traverse(arr) {
        for (const i in arr) {
          if (arr[i].type == '1') {
            arr[i].disabled = true
            if (arr[i].children && arr[i].children.length > 0) {
              this.traverse(arr[i].children)
            }
          }
        }
      },
      //清空筛选框事件
      Clearinput() {
        this.$refs.tree.filter('')
      },
      //保存
      handlesave() {
        let sellist = this.personnel.map(item => {
          return item.id
        })
        sellist.push(this.ZhuGuanLiYuan.id)
        const user_ids = sellist.join(',')
        update_staff_node({
          data: {
            ent_id: this.$store.state.ent_id,
            user_ids
          }

        }).then(res => {

          this.$message({
            type: 'success',
            message: '保存成功!'
          });
          console.log(res, 'sssssaaaaaaaaaavvvvvvvveeeeeeeeee');
        })
      },
      btnA() {
        this.$refs.tree.filter(this.filterText)
      },
      filterNode(value, data) {
        console.log(data.label)
        if (!value) return true
        return data.label.indexOf(value) !== -1
      },
      //删除
      handledelete(i) {
        console.log(i.staff_id, '管理员信息');


        let _this = this
        this.$confirm('此操作将删除该人员, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _this.$refs.tree.setChecked(i.id, false);
          
          _this.personnel = _this.personnel.filter(x => x.id !== i.id)
          


          //  _this.$refs.tree.setChecked(i.id, false);
          // this.$refs.tree.setCheckedKeys([])

          // let arr3 = []
          // this.personnel.forEach(x => {
          //   x.id = x.staff_id
          //   arr3.push(x.id)
          // })
          // this.$refs.tree.setCheckedKeys(arr3);

          this.$message({
            type: 'success',
            message: '删除成功!'
          });








          
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      //多选框改变事件
      handlechange(info, nodestatus, nodesubstatus) {
 
        let arr = this.$refs.tree.getCheckedNodes()
        let arr1 = []
        let arr3 = []
        // arr.unshift(this.ZhuGuanLiYuan)
        // arr3[this.ZhuGuanLiYuan.id]=this.ZhuGuanLiYuan
        arr.forEach(item => {
          if (item.type == '2') {
            if(this.ZhuGuanLiYuan.id!=item.id){
               if (arr3[item.id]) {

            } else {
              arr3[item.id] = item
            }
            }
           
          }
        });
        this.personnel = []
        for (const i in arr3) {
          this.personnel.push(arr3[i])
        }
        if (this.AllNum < this.personnel) {
          this.$message({
            type: 'info',
            message: '已超越最大限度,无法继续勾选!!!'
          })
          this.personnel.slice(0,this.AllNum)
          return

        }

      },
      handlebuy() {
        this.$router.push('/EnterpriseConfirm')
      },
    },
    created() {
      query_ent_primary_manager_detail({
        data: {
          ent_id: this.$store.state.ent_id,
        }
      }).then(res => {
        this.ZhuGuanLiYuan = res.body.data


         this.ZhuGuanLiYuan.id = this.ZhuGuanLiYuan.staff_id
        this.ZhuGuanLiYuan.label = this.ZhuGuanLiYuan.name
        this.ZhuGuanLiYuan.type = '2'
        // let arr = this.$refs.tree.getCheckedNodes()
        // this.personnel.unshift(this.ZhuGuanLiYuan)

        console.log(res, '多选框事件');
      })
    },
    data() {
      return {
        filterText: '',
        //判断默认选中数组
        TreeData: [],
        data: [],
        personnel: [],
        AllNum: '',
        defaultProps: {
          children: 'children',
          label: 'label'
        },


        ZhuGuanLiYuan: []
      }
    }
  }
</script>

<style lang="scss">
  // /deep/ .el-tree-node{
  //   .is-leaf + .el-checkbox .el-checkbox__inner{
  //       display: inline-block;
  //   }
  //   .el-checkbox .el-checkbox__inner{
  //       display: none;
  //   }
  // }
  .node-box {
    background: #f2f6fc;
    box-shadow: 0px 0px 10px #e8e8eb;
  }

  // .el-main {
  //
  //     background: #f2f6fc;
  //     box-shadow: 0px 0px 10px #e8e8eb;
  // }
  .buy {
    margin-left: 30px;
    cursor: pointer;
  }

  .node-righttop {
    color: #333;
    font-size: 20px;
    min-width: 600px;
    height: 195px;
    // margin-top: 145px;
    justify-content: space-between;
    align-items: center;
    display: flex;

    .node-button {
      width: 100px;
      margin-right: 50px;
      font-size: 18px;
      height: 50px;
      background: linear-gradient(to right, #fc4353, #ff5e41);
      text-align: center;
      line-height: 50px;
      color: white;
      border-radius: 10px;
    }
  }

  .el-main {
    background: #f2f6fc;
    -moz-box-shadow: 0px 0px 10px #e8e8eb;
    -webkit-box-shadow: 0px 0px 10px #e8e8eb;
    box-shadow: 0px 0px 10px #e8e8eb;
  }

  .node-box {
    width: 100%;
    height: 771px;
    display: flex;
    justify-content: space-between;
    background: #fff;
    margin-top: 20px;

    .node-left {
      width: 450px;
      height: 100%;

      .node-lefttitle {
        display: flex;
        align-items: center;
        margin-left: 22px;
        padding-top: 22px;

        img {
          width: 22px;
          height: 22px;
        }

        h4 {
          color: #333333;
          font-size: 15px;
          margin-left: 11px;
        }
      }

      .node-leftnum {
        width: 100%;
        height: 30px;
        margin-top: 20px;
        margin-left: 54px;
        font-size: 15px;
        color: #999999;

        span {
          color: #fc5160;
        }
      }

      .node-leftquree {
        .node-input {
          display: flex;
          margin-top: 21px;
        }

        p {
          font-size: 15px;
          margin-left: 43px;
          margin-top: 25px;
          color: #333;
        }

        input {
          border: 1px solid #cccccc;
          margin-left: 32px;
          width: 220px;
          height: 50px;
          border-radius: 10px;
        }

        button {
          border: none;
          background: linear-gradient(to right, #fc4353, #ff5e41);
          margin-left: 20px;
          width: 97px;
          height: 50px;
          border-radius: 10px;
          font-size: 15px;
          color: white;
        }
      }
    }

    .node-right {
      flex: 1;
      height: 100%;
      margin-left: 50px;

      .node-righttitle {
        font-size: 15px;
        color: #333333;
        // margin-top: 180px;
        // margin-left: 36px;
      }

      .node-per {
        width: 100%;
        height: 538px;
        margin-top: 30px;
        box-shadow: 0px 4px 10px #a8a8a8;
        overflow-y: auto;

        .node-persone:hover {
          background-color: #f9f9f9;
        }

        .node-persone {
          width: 100%;
          height: 70px;
          display: flex;
          align-items: center;

          img {
            width: 50px;
            height: 50px;
            margin-left: 41px;
            border-radius: 50%;
          }

          p {
            font-size: 15px;
          }

          .node-perlabel {
            width: 100px;
            margin-left: 20px;
            color: #333;
          }

          .node-oper {
            margin-left: 220px;
            color: #333;
          }
        }
      }
    }

    .node-tree {
      width: 405px;
      height: 538px;
      box-shadow: 6px 2px 8px #bfbfd1;
      margin-top: 20px;
      margin-left: 32px;
      font-size: 15px;
      overflow-y: auto;

      .node-treebody {
        margin-left: 30px;
        margin-top: 30px;
      }
    }

    .node-tree::-webkit-scrollbar {
      width: 5px;
    }

    .node-tree::-webkit-scrollbar-thumb {
      border-radius: 5px;
      width: 2px;
      background: #c8c8c8;
    }

    .node-tree::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f6f6fa;
    }

    .node-per::-webkit-scrollbar {
      width: 5px;
    }

    .node-per::-webkit-scrollbar-thumb {
      border-radius: 5px;
      width: 2px;
      background: #c8c8c8;
    }

    .node-per::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f6f6fa;
    }
  }
</style>